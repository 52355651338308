export const navigationButtons = {
  firstPage: {
    eng: "Home",
    rom: "Prima pagină",
  },
  team: {
    eng: "Team",
    rom: "Echipa",
  },
  courses: {
    eng: "Courses",
    rom: "Cursuri",
  },
  formsAndAnalyses: {
    eng: "Forms and analyses",
    rom: "Forme și analize",
  },
  counterPoint: {
    eng: "Counterpoint",
    rom: "Contrapunct",
  },
  harmony: {
    eng: "Harmony",
    rom: "Armonie",
  },
  tests: {
    eng: "Tests",
    rom: "Teste",
  },
  account: {
    eng: "My account",
    rom: "Cont",
  },
  register: {
    eng: "Register",
    rom: "Creează-ți cont!",
  },
  login: {
    eng: "Login",
    rom: "Conectează-te",
  },
};

export const homePageContent = {
  paragraphs: [
    {
      eng: "The MusiTopia project is born out of an intention to improve the approach, teaching, consolidation and assessment in arts education at university, in the field of Music, while providing a basis for future ideas, development plans and artistic projects.",
      rom: "Proiectul MusiTopia a apărut din dorința de a îmbunătăți modul de abordare, predare, consolidare și evaluare în cadrul educației universitare cu profil artistic, domeniul Muzică, constituind totodată o bază pentru alte idei din viitor, planuri de dezvoltare și proiecte artistice. ",
    },
    {
      eng: `In the first phase, MusiTopia is set up as a platform for research and development of music education, the National University of Arts "George Enescu" in Iasi being the oldest institution in the country in this sector of academic activity. For more than 160 years, Iasi has been the home of Romanian musicians who have become emblematic for our culture on an international level.`,
      rom: `În primă fază, MusiTopia se constituie sub forma unei platforme de cercetare și dezvoltare a învățământului artistic muzical, Universitatea Națională de Arte „George Enescu” din Iași fiind cea mai veche instituție din țară, în acest sector de activitate academică. Timp de peste 160 de ani, la Iași s-au format și au activat muzicieni români consacrați, care au devenit emblematici pentru cultura noastră pe plan internațional.`,
    },
    {
      eng: "In accordance with the long tradition of this institution and out of respect for all our predecessors, we want to carry forward the values promoted in the artistic space of Iasi by connecting to the strategies of modernization and internationalization targeted both by the Charter of the University of Arts in Iasi, and by the strategy of creating strong links with other institutions in the country and abroad, in the artistic sphere and other fields, whether human, social and even technical.",
      rom: "În concordanță cu tradiția îndelungată a acestei instituții și din respect pentru toți predecesorii noștri, ne dorim să ducem mai departe valorile promovate în spațiul artistic ieșean. Ne propunem racordarea la strategiile de modernizare și internaționalizare vizate atât prin Carta Universității de Arte din Iași, cât și prin strategia de creare a unor legături cât mai solide cu alte instituții din țară și străinătate, din sfera artistică și alte domenii, fie umane, sociale și chiar tehnice.",
    },
    {
      eng: "We are a team of 3 professors from the Faculty of Performance, Composition and Theoretical Music Studies, with the desire to innovate from the existing academic tradition by creating modern means, complementary to face-to-face teaching and by making information accessible.",
      rom: "Suntem o echipă alcătuită din 3 profesori ai Facultății de Interpretare, Compoziție și Studii Muzicale Teoretice, cu dorința de a inova pornind de la tradiția academică deja existentă, prin crearea unor mijloace moderne, complementare predării face-to-face și prin accesibilizarea informațiilor.",
    },
    {
      eng: "Musical Forms and Analysis, Harmony, Counterpoint are part of the body of fundamental and specialized subjects of study in the curricula of all university educational institutions in the country, as well as in most European countries, with slight differences in curricular organization.",
      rom: "Formele și analizele muzicale, Armonia, Contrapunctul fac parte din corpul disciplinelor fundamentale de studiu și al celor de specialitate din planurile de învățământ ale tuturor instituțiilor de învățământ universitar din țară, precum și în majoritatea statelor europene, cu mici diferențe de organizare curriculară.",
    },
    {
      eng: "Following exchanges of experience, international mobilities, participation in international conferences and congresses, workshops and training sessions for academic teachers, we have formed our own vision of directions for professional and institutional development.",
      rom: "În urma schimburilor de experiență, a participării la conferințe și congrese internaționale, la workshop-uri și sesiuni de formare pentru cadrele didactice academice, ne-am format o viziune proprie asupra direcțiilor de dezvoltare profesională și instituțională.",
    },
    {
      eng: "The teaching experience that we have assimilated in the last 10 years of university activity led us to the idea of a joint project, conceived as a website made available to our students, through an inter- and trans-disciplinary approach to the different disciplines of music theory, by creating bridges between theoretical knowledge and practical implementation and development of OPEN EDUCATIONAL RESOURCES, our main objective being to create easily accessible materials and resources.",
      rom: "Experiența didactică pe care am asimilat-o în ultimii 10 ani de activitate univeritară ne-a condus la ideea unui proiect comun, conceput ca un site web pus la dispoziția studenților noștri, printr-o abordare inter- și trans-disciplinară a diferitelor discipline din domeniul teoretic muzical, prin crearea de punți între cunoștințele teoretice și implementarea practică și dezvoltarea de OPEN EDUCATIONAL RESOURCES, obiectivul nostru principal fiind de a crea materiale și resurse ușor de accessat.",
    },
    {
      eng: "The platform is useful for intermediate and advanced students alike, providing the basics for training basic skills for a young musician at the age of preparation for a job in music. The three subjects, like many others in the curriculum of the Faculty of Performance, Composition and Theoretical Music Studies, require constant practice, particular attention in assimilating theoretical knowledge and applying it to specific topics. For this reason, attendance at courses and seminars is indispensable for understanding the rules and mechanisms of how music works from the perspective of articulation of form, harmonic development and organisation of sound planes at the level of contrapuntal writing, when the score requires it.",
      rom: "Platforma este utilă deopotrivă studenților de nivel mediu și avansat, oferind elementele de bază pentru formarea deprinderilor de bază pentru un tânăr muzician aflat la vârsta pregătirii pentru un loc de muncă în domeniul muzical. Cele trei discipline, precum multe altele din curriculum-ul Facultății de Interpretare, Compoziție și Studii Muzicale Teoretice, necesită un exercițiu constant, o atenție particulară în asimilarea cunoștințelor teoretice și aplicarea acestora în teme specifice. Din acest motiv, prezența la cursuri și seminarii este indispensabilă înțelegerii regulilor și mecanismelor de funcționare a muzicii din perspectiva articulării de formă, desfășurării armonice și organizării planurilor sonore la nivelul scriiturii contrapunctice, atunci când partitura impune acest lucru.",
    },
    {
      eng: "In addition to the analyses and homework done in class with the tutors, we found that students also need additional individual activities, as the time allocated to group classes is limited. On the other hand, homework assignments should not take up too much of the students' time, so we looked for an optimal solution to meet these needs without considerable effort.",
      rom: "Pe lângă analizele și temele rezolvate în clasă alături de profesorii îndrumători, am constatat că studenții au nevoie și de activități suplimentare individuale, întrucât timpul alocat orelor colective este limitat. Pe de altă parte, temele date pentru realizarea lor acasă nu trebuie să încarce excesiv timpul studenților, astfel încât am căutat o soluție optimă pentru satisfacerea acestor nevoi fără eforturi considerabile.",
    },
    {
      eng: "Our duty and responsibility as teachers in academia is very great, which is why we are constantly looking for strategies to improve the way we work with students, to adapt the content taught and the techniques to convey essential information to them.",
      rom: "Datoria și responsabilitatea noastră în calitate de profesori din spațiul academic sunt foarte mari, motiv pentru care căutăm constant strategii de perfecționare a modului de lucru cu studenții, de adaptare a conținuturilor predate și tehnicilor de a transmite informațiile esențiale pentru aceștia.",
    },
    {
      eng: "Music is omnipresent. In every cultural space, in every professional environment or sector of activity, there is music. So there is a need for professional musicians and support in developing modern working strategies.",
      rom: "Muzica este omniprezentă. În orice spațiu cultural, în orice mediu profesional sau sector de activitate, există muzică. Așadar, este nevoie de muzicieni profesioniști și de sprijin în dezvoltarea unor strategii moderne de lucru.",
    },
    {
      eng: `MusiTopia is a place where all our ideas become connected, seen as pieces in the great puzzle of the music field, a project that allows us to create and design content, where we can "play" and reconfigure our ideas by constantly adapting and improving.`,
      rom: `MusiTopia este un loc în care toate ideile noastre devin conectate, văzute ca piese în marele puzzle al domeniului muzical, un proiect care ne permite să creem și să proiectăm conținutul, unde ne putem „juca” și reconfigura ideile, prin adaptare și îmbunătățire constantă. `,
    },
    {
      eng: "MusiTopia is at the beginning of a fascinating journey through the world of music and has tremendous potential for academic, teaching, practical, research and creative work.",
      rom: "MusiTopia se află la începutul unei călătorii fascinante prin universul muzicii și are un potențial extraordinar pentru activitatea academică, didactică, practică, de cercetare și creație.",
    },
  ],
};

export const aboutUsContent = {
  title: {
    eng: "Who are we?",
    rom: "Cine suntem noi?",
  },
  descriptions: [
    {
      eng: "Our team consists of musicologist lecturer PhD. Mihaela-Georgiana Balan, composer lecturer PhD Paul Pintilie and composer assist. prof. PhD. Emanuela-Izabela Vieriu, academic professors at the Faculty of Performance, Composition and Music Theory Studies of UNAGE Iasi.",
      rom: "Echipa noastră este formată din lect. univ. dr. Mihaela-Georgiana Balan, lect. univ. dr. Paul Pintilie și asist. univ. dr. Emanuela-Izabela Vieriu, cadre didactice ale Facultății de Interpretare, Compoziție și Studii Muzicale Teoretice din cadrul UNAGE Iași.",
    },
    {
      eng: `We are creative, open musicians, passionate about what we do, rigorous and flexible teachers at the same time. We want to reinvent our ideas throughout our careers, to give birth to other beautiful projects, because we are eager to constantly learn, evolve, develop new directions of research, musical creation and improvement of academic education.`,
      rom: `Suntem muzicieni creativi, deschiși, pasionați de ceea ce facem, profesori riguroși și flexibili deopotrivă. Vrem să ne reinventăm ideile pe parcursul carierei, să dăm naștere altor proiecte frumoase, pentru că suntem dornici să învățăm constant, să evoluăm, să dezvoltăm noi direcții de cercetare, creație muzicală și îmbunătățire a educației academice.`,
    },
  ],
  members: [
    {
      name: "Mihaela Georgiana Balan",
      paragraphs: [
        {
          eng: "Mihaela Georgiana Balan (b. 1987) is lecturer PhD at the National University of Arts „George Enescu” in Iași, having a broad teaching and musicological activity based on research, involvement in artistic projects, guidance and support for students. Being herself a graduate and PhD specialist in musicology at this institution, she dedicated since her training years to the events organized within the academic space, participated to scientific symposiums, specialized competitions and artistic events. She is currently teaching Musical Forms and Analysis, History of Music, Musicology and Applied Research.",
          rom: "",
        },
      ],
    },
  ],
};
export const firstTeamMember = {
  text: [
    {
      rom: "Mihaela Georgiana Balan (n. 1987) este lector universitar la Universitatea Națională de Arte „George Enescu” din Iași, desfășurând o activitate didactică și muzicologică bazată pe cercetare, implicare în proiecte artistice, îndrumare și sprijin pentru studenți. Ea însăși absolventă și doctor în muzicologie în cadrul acestei instituții, s-a dedicat încă din anii de formare muzicală evenimentelor organizate în cadrul academic, simpozioanelor de comunicări științifice, concursurilor de specialitate și manifestărilor artistice. În prezent, predă cursuri și seminarii la disciplinele Forme și analize muzicale, Istoria muzicii, Muzicologie, Cercetare aplicată.",
      eng: "Mihaela Georgiana Balan (b. 1987) is lecturer PhD at the National University of Arts „George Enescu” in Iași, having a broad teaching and musicological activity based on research, involvement in artistic projects, guidance and support for students. Being herself a graduate and PhD specialist in musicology at this institution, she dedicated since her training years to the events organized within the academic space, participated to scientific symposiums, specialized competitions and artistic events. She is currently teaching courses and seminars for the disciplines entitled Musical Forms and Analysis, History of Music, Musicology and Applied Research.",
    },
    {
      rom: `În calitate de cercetător, este autoarea volumului de analiză muzicologică intitulat „Limbaj, structură, semantică muzicală în sonatele pentru pian ale compozitorilor ruși din prima jumătate a secolului al XX-lea”, publicat la Editura Muzicală, București, 2022, ISBN 978-973-42-1237-8, ISMN 979-0-69491-241-3.`,
      eng: `As a researcher, she is the author of the volume of musicological analysis entitled "Language, Structure, Musical Semantics in the Piano Sonatas of Russian Composers from the first half of the 20th century", published by Editura Muzicală, Bucharest, 2022, ISBN 978-973-42-1237-8, ISMN 979-0-69491-241-3.`,
    },
    {
      rom: "A scris studii muzicologice pentru diferite publicații în limba română și engleză, cele din ultimii 10 ani fiind vizibile în bazele de date internaționale, numeroase articole de critică muzicală, cronici, recenzii, reportaje pentru reviste muzicale și site-uri specializate din acest domeniu. Câteva titluri semnificative sunt:",
      eng: "She has written musicological studies for various publications in Romanian and English, those of the last 10 years being visible in international databases, numerous articles of music criticism, reviews, reports for music magazines and specialized websites in this field. Some significant titles are:",
    },
  ],
  list: [
    {
      rom: `Richard Taruskin - „Titan” of Universal Musicology. On the Fascination and Imperative of „Cursed Questions” (în ARTES. Journal of Musicology, nr. 27-28/2023);`,
      eng: `Richard Taruskin - „Titan” of Universal Musicology. On the Fascination and Imperative of 'Cursed Questions' (in ARTES. Journal of Musicology, no. 27-28/2023);`,
    },
    {
      rom: `„Uneltele muzologiei (Epistemologica)” de Oleg Garaz, Editura Eikon, colecția Universitas, seria Muzicologie, București, 2022 - sau despre teoria întregului în plan muzicologic (recenzie de carte publicată în ARTES. Journal of Musicology, nr. 27-28/2023);`,
      eng: `“The Tools of Musicology” (Epistemologica) by Oleg Garaz, Eikon Publishing House, Universitas Collection, Musicology Series, Bucharest, 2022 - or the Theory of Everything in Musicology (book review published in ARTES. Journal of Musicology, no. 27-28/2023);`,
    },
    {
      rom: `Nikolay Myaskovsky - 140th birth anniversary. Rediscovering the composer in the present times (în ARTES. Journal of Musicology, nr. 25/2022);`,
      eng: `Nikolay Myaskovsky - 140th birth anniversary. Rediscovering the composer in the present times (in ARTES. Journal of Musicology, no. 25/2022);`,
    },
    {
      rom: `In  search  of  spiritual  identity:  Comparative  aspects between  Gustav  Mahler  and  Alfred  Schnittke (în volumul de comunicări - Proceedings Volume - al celei de a VIII-a ediții din cadrul International Multidisciplinary Scientific Conferences on Social Sciences, Arts & Humanities - SGEM 2021, Albena, Bulgaria).`,
      eng: `In search of spiritual identity: Comparative aspects between Gustav Mahler and Alfred Schnittke (in the Proceedings Volume of the 8th edition of International Multidisciplinary Scientific Conferences on Social Sciences, Arts & Humanities - SGEM 2021, Albena, Bulgaria, 2021).`,
    },
    {
      rom: `Polystylism in the context of Postmodern Music. Alfred Schnittke's Concerti Grossi (în ARTES. Journal of Musicology, nr. 23/2021);`,
      eng: `Polystylism in the context of Postmodern Music. Alfred Schnittke's Concerti Grossi (in ARTES. Journal of Musicology, no. 23/2021);`,
    },
    {
      rom: `Polifonie literară dostoievskiană în ipostaza lirico-teatrală a operei „Jucătorul” de Serghei Prokofiev / Dostoevskian Literary Poliphony as Lyrical-Theatrical Hypostasis of Sergey Prokofiev's Opera'The Gambler' (în ARTES. Revistă de Muzicologie / ARTES. Journal of Musicology, nr. 21/2020);`,
      eng: `Dostoievskian Literary Polyphony as Lyrical-Theatrical Hypostasis of Sergey Prokofiev's Opera 'The Gambler' (in ARTES. Journal of Musicology, no. 21/2020);`,
    },
    {
      rom: `Aspecte ale semanticii muzicale din perspectiva structuralismului, semioticii şi naratologiei / Aspects of Musical Semantics from the Perspective of Structuralism, Semiotics and Narratology (în ARTES. Revistă de Muzicologie / ARTES. Journal of Musicology, nr. 18/2018);`,
      eng: `Aspects of Musical Semantics from the Perspective of Structuralism, Semiotics and Narratology (in ARTES. Journal of Musicology, no. 18/2018);`,
    },
    {
      rom: `Aleksandr Scriabin's Structural and Harmonical Conception Revealed in Piano Sonatas no. 3 and 7 (în Musicology Today. Journal of the National University of Music Bucharest nr. 25/2016);`,
      eng: `Alexander Scriabin's Structural and Harmonical Conception Revealed in Piano Sonatas no. 3 and 7 (in Musicology Today. Journal of the National University of Music Bucharest no. 25/2016);`,
    },
    {
      rom: `Franz Liszt - a Dilemma of Stylistic Interferences, studiu realizat cu ocazia participării la cel de-al 12-lea Congres Mondial de Semiotică organizat de New Bulgarian University din Sofia, 16-20 septembrie 2014, publicat în volumul New Semiotics. Between Tradition and Innovation: proceedings of the 12. World Congress of Semiotics, ed. Kristian Bankov, IASS Publications & NBU Publishing House (ISSN 2414-6862).`,
      eng: `Franz Liszt - a Dilemma of Stylistic Interferences, research accomplished on the occasion of participating to the 12th World Congress of Semiotics organized by the New Bulgarian University in Sofia, 16-20 Sept. 2014, published in the volume New Semiotics. Between Tradition and Innovation: proceedings of the 12. World Congress of Semiotics, ed. Kristian Bankov, IASS Publications & NBU Publishing House (ISSN 2414-6862).`,
    },
    {
      rom: `Dan Dediu's Artistic Identity Reflected in „Frenesia” and „Mantrana” (în volumul realizat cu ocazia International Musicology Conference, 4-7 iunie 2013, Iași, Series: Eastern European Studies in Musicology, Vol. II)`,
      eng: `Dan Dediu's Artistic Identity Reflected in 'Frenesia' and 'Mantrana' (in the Proceedings Volume of the International Musicology Conference, 4-7 June 2013, Iași, Series: Eastern European Studies in Musicology, Vol. II)`,
    },
    {
      rom: `Și altele (disponibile în bazele de date internaționale Sciendo, CEEOL, ERIH Plus, Google Scholar, Academia.edu)`,
      eng: `And other (available on Sciendo, CEEOL, ERIH Plus, Google Scholar, Academia.edu)`,
    },
  ],
};
export const secondTeamMember = {
  text: [
    {
      rom: "Paul Pintilie (n. 1990) este lector universitar la Universitatea Națională de Arte „George Enescu” din Iași, unde predă disciplinele Armonie (clasico-romantică), Compoziție muzicală și Aranjamente corale. Cu un traseu artistic îndelungat, în care muzica a constituit o preocupare importantă încă din primii ani de viață, a manifestat un interes deosebit pentru pian, dezvoltând o pasiune care l-a condus, ulterior, spre studiile de specialitate în domeniul muzical.",
      eng: "Paul Pintilie (b. 1990) is lecturer PhD at the National University of Arts “George Enescu” in Iasi, where he teaches Harmony (of the classical-romantic period), Musical Composition and Choral Arrangements. With a long artistic background, in which music has been an important concern since his early years, he has shown a particular interest in the piano, developing a passion that led him, later on, towards specialized studies in the musical field.",
    },
    {
      rom: "După ce a urmat cursurile Colegiului Național de Arte „Octav Băncilă” din Iași, secția Pian Principal, și-a continuat pregătirea în cadrul studiile universitare de licență și master la Universitatea Națională de Arte „George Enescu” Iași, specializarea Compoziție clasică, la clasa prof. univ. dr. Ciprian Ion.",
      eng: "After attending the National College of Arts “Octav Băncilă” in Iasi, Piano section, he continued his studies at the National University of Arts “George Enescu” in Iași, specializing in Composition, under the guidance of Prof. Ciprian Ion.",
    },
    {
      rom: "Studiul pianului, devenit între timp profesie și activitate practică constantă, s-a desăvârșit prin inițierea sa ca profesor corepetitor la colegiul absolvit, iar studiile superioare au condus către cercetarea doctorală, finalizându-se cu titulatura de Doctor în Muzică în decembrie 2019, sub îndrumarea prof. univ. dr. Gheorghe Duțică.",
      eng: "The study of piano playing, which has meanwhile become a profession and a constant practical activity, was completed by his initiation as a corepetitor teacher at the previously graduated college, and his higher studies led to doctoral research, ending with the degree of Doctor of Music in December 2019, under the supervision of Prof. Gheorghe Duțică.",
    },
    {
      rom: "Pe parcursul formării sale, Paul Pintilie a avut onoarea de a studia și a absorbi informații de la profesori iluștri ai artei compoziției muzicale, precum Wolfram Wagner, Péter Szegő, Cristian Misievici și Viorel Munteanu.",
      eng: "During his training, Paul Pintilie had the honour of studying and absorbing information from famous teachers of the art of musical composition, such as Wolfram Wagner, Péter Szegő, Cristian Misievici and Viorel Munteanu.",
    },
    {
      rom: `Compozițiile sale acoperă o arie diversă, de la lucrări solo pentru pian, flaut, clarinet și până la lucrări camerale (cvintete de alămuri, cvartete de coarde) și simfonice (Missa Harmonis - pentru cor, orchestră și combo de jazz, Aproape de Soare - Concert pentru orchestră), precum și muzică scrisă pentru piese de teatru, lucrări Pop, Jazz, etc.`,
      eng: `His compositions cover a diverse range, from solo works for piano, flute, clarinet to chamber (brass quintets, string quartets) and symphonic works ("Missa Harmonis" - for choir, orchestra and jazz combo, "Aproape de Soare" [Close to the Sun] - Concerto for orchestra), as well as music written for theater plays, Pop works, Jazz ensembles, etc.`,
    },
    {
      rom: `Fiind totodată și un iubitor al muzicii de Jazz și Pop, studiul acestor ramuri stilistice decurg în paralel cu celelalte activități, fapt concretizat prin participarea repetată la concursurile și festivalurile de profil (Festivalurile de Jazz de la Târgu Mureș, Sibiu, „Locomotiva Jazz” din Budapesta, etc.). Întâlnirile cu artiști contemporani ce au revoluționat muzica pop au contribuit, de asemenea, la formarea sa continuă, împărțind scena cu: Jennifer Batten și Sam Sims (instrumentiști în band-ul lui Michael Jackson), membrii formațiilor Smokie și Scorpions.`,
      eng: `Being also a lover of Jazz and Pop music, the study of these stylistic directions evolves in parallel with his other activities, which is materialized by frequent participation in competitions and festivals (Jazz Festivals in Târgu Mureș, Sibiu, “ Jazz Locomotive” in Budapest, etc. Meetings with contemporary artists who have revolutionized Pop music have also contributed to his continuous training, sharing the stage with: Jennifer Batten and Sam Sims (instrumentalists in Michael Jackson's band), members of Smokie and Scorpions.`,
    },
    {
      rom: `Paul Pintilie consideră că orice informație teoretică de natură academică este o condiție sine qua non a actului artistic interpretativ sau creator, fără a marginaliza, desigur, forța simțirii și a intuiției muzicale ce conferă unicitate fiecărui muzician în parte.`,
      eng: `Paul Pintilie considers that any theoretical information of academic nature is a sine qua non condition of the performing act or creative artistic process, without marginalizing, of course, the force of feeling and musical intuition that gives uniqueness to each individual musician.`,
    },
  ],
};
export const thirdTeamMember = {
  text: [
    {
      rom: "Emanuela Izabela Vieriu (n. 1991) este compozitor, dirijor, activând ca asistent universitar în cadrul Universității Naționale de Arte „George Enescu” din Iași, unde predă seminariile la disciplinele Contrapunct (palestrinian și bachian), Compoziție și Muzică de film. Atât studiile de licență, cât și cele masterale și doctorale le-a absolvit în cadrul aceleiași universități, fiind dublu licențiată în Compoziție clasică (2015), la clasa prof. univ. dr. Ciprian Andrei Ion, precum și în Dirijat orchestră (2016), la clasa prof. univ. dr. asociat Dumitru Goia. A continuat cu masterul în domeniul compoziției, susținând examenul de disertație în 2017, continuând apoi cu studiile doctorale sub îndrumarea prof. univ dr. Gh. Duțică și prof. univ. dr. C. A. Ion",
      eng: "Emanuela Izabela Vieriu (b. 1991) is a composer, conductor, working as an assistant professor PhD at the National University of Arts “George Enescu” in Iași, where she teaches seminars in Counterpoint (in Palestrina and Bach styles), Composition and Film Music. She graduated from the same university with a double degree in Classical Composition (2015), in the class of Prof. Ciprian Andrei Ion, and in Orchestra Conducting (2016), in the class of Prof. Dumitru Goia. She continued with a Master degree in composition, submitting the dissertation exam in 2017, and then continued with doctoral studies under the supervision of Prof. Gh. Duțică and Prof. C. A. Ion.",
    },
    {
      rom: "Cercetarea pe care a efectuat-o în domeniul compoziției s-a materializat în teza de doctorat cu titlul „Muzica de film. Coduri, convenții și interferențe stilistice”, susținută în octombrie 2021 la Iași, primind calificativul Summa cum laude. Cursul de Muzică de film constituie o concretizare a cercetării doctorale în plan didactic academic, prin organizarea conținuturilor și asigurarea continuității în demersul educațional. Studenții au ocazia să studieze codurile și convențiile din muzica de film, din producții date, direct conectate cu genurile cinematografice, subgenurile, precum și genurile hibrid, dar și tipologiile caracteriale.",
      eng: "The research she made in the field of composition materialized in her doctoral thesis entitled “Film Music. Codes, conventions and stylistic interferences”, supported in the fall of 2021 in Iasi, receiving the qualification Summa cum laude. The Film Music course is a concretization of the doctoral research in the academic didactic field, by organizing the contents and ensuring continuity in the educational endeavour. Students have the opportunity to study codes and conventions in film music from given productions, directly connected to cinematic genres, subgenres, as well as hybrid genres and character typologies.",
    },
    {
      rom: "Anii de studiu au fost susținuți nu doar de muncă intensă, pasiune și curiozitate, ci și de dorința de perfecționare, atât din perspectiva componistică, dar și din cea dirijorală, prin participarea constantă la cursuri de măiestrie, festivaluri, conferințe și workshop-uri, schimburi și mobilități ERASMUS, atât activ cât și pasiv, pe plan intern și internațional. Mai mult, a moderat și organizat ateliere de compoziție, pentru pasionații de muzică, în sensul larg al cuvântului, precum și pentru studenții de la conservator.",
      eng: "The years of study were supported not only by intense work, passion and curiosity, but also by the desire to improve, both from a compositional and conducting perspective, through constant participation in master classes, festivals, conferences and workshops, ERASMUS exchanges and mobilities, both active and passive, internal and international. Moreover, she was able to moderate and organize composition workshops, for music enthusiasts in the broadest sense of the word, as well as for students. Of course, she aimed to improve her compositional technique by writing and practicing daily, so that she could acquire specific skills and abilities that would help her become efficient, productive, and produce results of a certain sonic quality.",
    },
    {
      rom: "Nu în ultimul rând, experiența de profesor în mediul preuniversitar, între anii 2015-2019 a stimulat-o, deoarece a observat punctele slabe și forte în organizarea conținuturilor, a avut oportunitatea de a pune în practică ideile proprii într-o manieră logică și organizată și s-a apropiat de nevoile elevilor. Predând disciplinele Armonie, Forme și Genuri muzicale, Ansamblu coral, Ansamblu orchestral la Liceul de Artă „Ștefan Luchian” din Botoșani, a contribuit la formarea tehnicii interpretative în orchestră, la acumularea, problematizarea și abstractizarea unor cunoștințe solide despre studiul acordurilor, precum și despre tiparele formale, relațiile acestora cu genurile muzicale, modul în care erau percepute în anumite epoci, perioade stilistice, precum și raportări contemporane la diverse tematici în lucru.",
      eng: "Last but not least, the experience of being a teacher in the pre-university environment, between the years 2015-2019, helped Izabela enormously, because she observed the weak and strong points in the organization of contents, she had the opportunity to put into practice her own ideas in a logical and organized manner and she was also able to approach the students' needs. Teaching the disciplines Harmony, Musical Forms and Genres, Choral Ensemble, Orchestral Ensemble at the “Ștefan Luchian” Art High School in Botoșani, she contributed to the formation of the performing technique in the orchestra, to the accumulation, problematization and abstraction of solid knowledge about the study of chords. At the same time, she taught formal patterns, studying with her students the relationships between structures and musical genres, the way they were perceived in certain eras, stylistic periods, as well as contemporary relations on various themes in a working process.",
    },
  ],
};
export const loginPage = {
  email: {
    eng: "E-mail address",
    rom: "Adresa de E-mail",
  },
  password: {
    eng: "Password",
    rom: "Parolă",
  },
  login: {
    eng: "Login",
    rom: "Conectează-te",
  },
  forgotPassword: {
    eng: "Forgot password?",
    rom: "Ai uitat parola?",
  },
  newPassword: {
    eng: "Reset",
    rom: "Resetează",
  },
  emailSent: {
    eng: "A reset password email has beed sent.",
    rom: "Un email pentru resetare parola a fost trimis.",
  },
  emailSentError: {
    eng: "A reset password email couldn't be sent.",
    rom: "Un email pentru resetare parola nu a putut fi trimis.",
  },
};
export const validationEmail = {
  congrats: {
    eng: "Congratulation, ",
    rom: "Felicitări",
  },
  message: {
    eng: "Your account has been succesfuly validated.",
    rom: "Contul dvs. a fost validat cu succes.",
  },
};
