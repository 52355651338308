import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";

const Slider = ({ images }) => {
  const [curSlide, setCurSlide] = useState(0);
  const intervalRef = useRef(null);

  useEffect(() => {
    const slides = document.querySelectorAll(".slide");
    slides.forEach((slide, indx) => {
      slide.style.transform = `translateX(${indx * 100}%)`;
    });

    intervalRef.current = setInterval(() => {
      nextSlide();
    }, 4000);

    return () => clearInterval(intervalRef.current);
  }, []);

  const nextSlide = () => {
    setCurSlide((prevSlide) =>
      prevSlide === images.length - 1 ? 0 : prevSlide + 1
    );
    resetInterval();
  };

  const prevSlide = () => {
    setCurSlide((prevSlide) =>
      prevSlide === 0 ? images.length - 1 : prevSlide - 1
    );
    resetInterval();
  };

  const resetInterval = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      nextSlide();
    }, 4000);
  };

  useEffect(() => {
    const slides = document.querySelectorAll(".slide");
    slides.forEach((slide, indx) => {
      slide.style.transform = `translateX(${100 * (indx - curSlide)}%)`;
    });
  }, [curSlide]);

  return (
    <div className="slider">
      {images.map((image, index) => (
        <div key={index} className="slide">
          <img src={image} alt="" />
        </div>
      ))}
      <div className="slider-arrow-left" onClick={prevSlide}></div>
      <div className="slider-arrow-right" onClick={nextSlide}></div>
    </div>
  );
};

export default Slider;
