import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "./styles.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
function PdfViewer({ pdf }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const nextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const prevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  return (
    <div>
      <div className="controls">
        <button
          className={`controls__prev controls__general ${
            pageNumber === 1 ? "controls__disabled" : "controls__active"
          }`}
          onClick={prevPage}
          disabled={pageNumber === 1}
        >
          Prev
        </button>
        <button
          className={`controls__next controls__general ${
            pageNumber === numPages ? "controls__disabled" : "controls__active"
          }`}
          onClick={nextPage}
          disabled={pageNumber === numPages}
        >
          Next
        </button>
      </div>

      <Document
        file={pdf}
        onLoadSuccess={onDocumentLoadSuccess}
        onContextMenu={(e) => e.preventDefault()}
        className="pdf-container"
      >
        <Page pageNumber={pageNumber} />
      </Document>
    </div>
  );
}

export default PdfViewer;
