import React, { useState } from "react";
import Slider from "../../components/utils/Slider/Slider.jsx";
import firstSliderImage from "../../sources/homepage-slider-1.jpg";
import secondSliderImage from "../../sources/homepage-slider-2.jpg";
import thirdSliderImage from "../../sources/homepage-slider-3.jpg";
import { homePageContent } from "../../translations/translations.js";
import "./styles.scss";
function Home() {
  const [lang, setLang] = useState("rom");
  const paragraphs = homePageContent.paragraphs.map(
    (paragraph) => paragraph[lang]
  );

  const images = [firstSliderImage, secondSliderImage, thirdSliderImage];
  const homePageTitle = "MusiTopia";
  return (
    <div className="home-landing-page">
      <div class="homepage-title">
        <div class="inner">
          <span>{homePageTitle}</span>
        </div>
        <div class="inner">
          <span>{homePageTitle}</span>
        </div>
      </div>
      <Slider images={images} className="slider-container" />
      <div className="home-container">
        {paragraphs.map((paragraph, index) => (
          <h4
            key={index}
            className={`home-text ${
              index === paragraphs.length - 1 ? "home-text__last" : ""
            }`}
          >
            {paragraph}
          </h4>
        ))}
      </div>
    </div>
  );
}

export default Home;
