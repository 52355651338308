export const getCategoryOptions = async (setOptions, url) => {
  const settings = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "api-key": process.env.REACT_APP_API_KEY,
    },
  };

  const apiCall = await fetch(url, settings);
  const data = await apiCall.json();
  const sortedData = data.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );

  setOptions(sortedData);
};

export const handleImageChange = (
  e,
  questionIndex,
  questions,
  setQuestions,
  update
) => {
  const newQuestions = [...questions];
  const file = e.target.files[0];

  if (file) {
    const reader = new FileReader();
    reader.onload = (event) => {
      const imageData = event.target.result;

      newQuestions[questionIndex].file = URL.createObjectURL(file);
      newQuestions[questionIndex].fileUpdate = update ? true : false;
      newQuestions[questionIndex].fileObj = imageData.split(",")[1];
      newQuestions[questionIndex].fileName = file.name.split(".")[0];
      newQuestions[questionIndex].fileType = file.name.split(".").pop();
      setQuestions(newQuestions);
    };

    reader.readAsDataURL(file);
  }
};

export const handleRemoveQuestionFile = (
  setQuestions,
  questions,
  questionIndex
) => {
  const newQuestions = [...questions];

  newQuestions[questionIndex].file = null;
  newQuestions[questionIndex].fileUpdate = true;
  newQuestions[questionIndex].fileObj = null;
  newQuestions[questionIndex].fileType = null;
  newQuestions[questionIndex].fileName = null;
  newQuestions[questionIndex].filePath = null;
  setQuestions(newQuestions);
};
export const handleCategoryChange = (
  option,
  setCategory,
  setActiveCategory
) => {
  setCategory(option.rom);
  const dropdown = document.querySelector(".dropdown-menu");
  dropdown.classList.remove("show-dropdown");
  dropdown.classList.toggle("hide-dropdown");
  setActiveCategory(option._id);
};

export const handleDropdownToggle = () => {
  const dropdown = document.querySelector(".dropdown-menu");
  dropdown.classList.remove("hide-dropdown");

  dropdown.classList.toggle("show-dropdown");
};

export const handleAddQuestion = (setQuestions, questions) => {
  setQuestions([...questions, { text: "", answers: [{ text: "" }] }]);
};
export const handleRemoveQuestion = (
  setQuestions,
  questions,
  indexToRemove
) => {
  if (indexToRemove >= 0 && indexToRemove < questions.length) {
    const updatedQuestions = [...questions];
    updatedQuestions.splice(indexToRemove, 1);
    setQuestions(updatedQuestions);
  }
};
export const handleQuestionTextChange = (
  event,
  index,
  questions,
  setQuestions
) => {
  const newQuestions = [...questions];
  newQuestions[index].text = event.target.value;
  setQuestions(newQuestions);
};

export const handleAddAnswer = (index, questions, setQuestions) => {
  const newQuestions = [...questions];
  newQuestions[index].answers.push({ text: "" });
  setQuestions(newQuestions);
};

export const handleDeleteAnswer = (
  questionIndex,
  answerIndex,
  questions,
  setQuestions
) => {
  const newQuestions = [...questions];
  newQuestions[questionIndex].answers.splice(answerIndex, 1);
  setQuestions(newQuestions);
};
export const handleAnswerTextChange = (
  event,
  questionIndex,
  answerIndex,
  questions,
  setQuestions
) => {
  const newQuestions = [...questions];
  newQuestions[questionIndex].answers[answerIndex].text = event.target.value;
  setQuestions(newQuestions);
};

export const handleAnswerCheck = (
  questionIndex,
  answerIndex,
  checked,
  setChecked
) => {
  const newChecked = [...checked];
  newChecked[questionIndex] = newChecked[questionIndex] || [];
  newChecked[questionIndex][answerIndex] =
    !newChecked[questionIndex][answerIndex];
  setChecked(newChecked);
};

export const fetchTestData = async (testId, setTest) => {
  try {
    const settings = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
      },
    };
    const apiCall = await fetch(
      `${process.env.REACT_APP_API_URL}/api/test-details/${testId}`,
      settings
    );
    const data = await apiCall.json();
    setTest(data);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const fetchCourseData = async (courseId, setCourse, setLoading) => {
  try {
    const settings = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.REACT_APP_API_KEY,
      },
    };
    const apiCall = await fetch(
      `${process.env.REACT_APP_API_URL}/api/course-details/${courseId}`,
      settings
    );
    const data = await apiCall.json();
    setCourse(data);
    setLoading(false);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
export const handlePDFChange = (e, setFileName, setFileObject, update) => {
  const file = e.target.files[0];

  if (file) {
    const reader = new FileReader();
    reader.onload = (event) => {
      const fileData = event.target.result;
      const fileObj = {
        data: fileData.split(",")[1],
        name: file.name.split(".pdf")[0],
        type: file.name.split(".").pop(),
        fileUpdate: update ? true : false,
      };
      setFileName(file.name);
      setFileObject(fileObj);
    };
    reader.readAsDataURL(file);
  }
};

export const handleRemoveFile = (setFileName, setFileObject) => {
  setFileName("");
  setFileObject({});
};

export const getDataListByCategory = async (
  setTotalPages,
  setTestsData,
  url,
  setLoading
) => {
  const settings = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "api-key": process.env.REACT_APP_API_KEY,
    },
  };

  try {
    const apiCall = await fetch(url, settings);
    const data = await apiCall.json();
    setTotalPages(data.totalPages);
    setTestsData(data.data);
    setLoading(false);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
