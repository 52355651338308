import React, { useEffect, useState } from "react";
import { getCategoryOptions } from "../../../components/utils/helper";
import DashboardMenu from "../DashboardMenu/DashboardMenu";
import "./styles.scss";

function TestCategories() {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    getCategoryOptions(
      setOptions,
      `${process.env.REACT_APP_API_URL}/api/test-categories`
    );
  }, []);

  return (
    <div className="dashboard">
      <DashboardMenu />
    </div>
  );
}

export default TestCategories;
