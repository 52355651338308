import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  fetchCourseData,
  getCategoryOptions,
  handleCategoryChange,
  handleDropdownToggle,
  handlePDFChange,
  handleRemoveFile,
} from "../../../components/utils/helper.js";
import { AuthContext } from "../../../contexts/AuthProvider.jsx";
import DashboardMenu from "../DashboardMenu/DashboardMenu";
import "./styles.scss";

function UpdateCourse() {
  const auth = useContext(AuthContext);
  const [category, setCategory] = useState("Select Category");
  const [options, setOptions] = useState([]);
  const [error, setError] = useState(false);
  const [activeCategory, setActiveCategory] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileObject, setFileObject] = useState({
    data: null,
    name: null,
    type: null,
    fileUpdate: false,
  });
  const [availability, setAvailability] = useState(true);
  const { courseId } = useParams();
  const [course, setCourse] = useState(null);

  useEffect(() => {
    getCategoryOptions(
      setOptions,
      `${process.env.REACT_APP_API_URL}/api/course-categories`
    );
    fetchCourseData(courseId, setCourse);
  }, [courseId]);

  useEffect(() => {
    if (course) {
      setActiveCategory(course.category._id);
      setCategory(course.category.rom);
      setAvailability(course.availability);
      setFileObject({
        data: null,
        name: course.file.fileName,
        type: course.file.fileType,
        fileUpdate: false,
      });
    }
  }, [course]);

  const updateCourse = async (event) => {
    event.preventDefault();

    const courseName = document.getElementById("courseName").value;
    const order = document.getElementById("order").value;

    const updatedCourse = {
      courseId: courseId,
      name: courseName,
      order: parseInt(order),
      category: activeCategory,
      availability: availability,
      file: fileObject,
      updatedFile: fileObject.fileUpdate,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/updateCourse`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "api-key": process.env.REACT_APP_API_KEY,
            token: auth.token,
          },
          body: JSON.stringify(updatedCourse),
        }
      );

      if (response.status !== 200) {
        setError(true);
        return;
      }
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="dashboard">
      <DashboardMenu />
      {course && (
        <div className="test-form-container">
          <div className="title-container">
            <h1 className="title">Update Course</h1>
          </div>
          <div className="form-container">
            <form onSubmit={updateCourse}>
              <div className="form-group">
                <label htmlFor="courseName">Name:</label>
                <input
                  type="text"
                  className="custom-title"
                  id="courseName"
                  defaultValue={course.name}
                />
              </div>
              <div className="form-group">
                <label htmlFor="order">Order:</label>
                <input
                  type="text"
                  className="order"
                  id="order"
                  defaultValue={course.order}
                />
              </div>
              {/* Category Dropdown */}
              <div className="form-group form-group__category">
                <label htmlFor="category">Category:</label>
                <div className="dropdown">
                  <button type="button" onClick={handleDropdownToggle}>
                    {category}
                  </button>
                  <div className="dropdown-menu">
                    {Array.isArray(options) &&
                      options.map((option, index) => (
                        <button
                          key={option._id}
                          className="dropdown-item"
                          onClick={() =>
                            handleCategoryChange(
                              option,
                              setCategory,
                              setActiveCategory
                            )
                          }
                          type="button"
                        >
                          {option.rom}
                        </button>
                      ))}
                  </div>
                </div>
              </div>

              {/* Availability Toggle */}
              <div className="form-group">
                <label htmlFor="availability">Availability:</label>
                <div className="availability-toggle">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={availability}
                      onChange={() => setAvailability(!availability)}
                    />
                    <span className="slider-box"></span>
                  </label>
                  <span className="availability-label">
                    {availability ? "On" : "Off"}
                  </span>
                </div>
              </div>

              {/* File Upload Section */}
              <div className="file-info">
                {fileObject.name && (
                  <p>
                    <strong>Current File:</strong> {fileObject.name}
                  </p>
                )}
              </div>
              <div className="file-buttons">
                <label className="question-file-upload">
                  <span>Upload PDF</span>
                  <input
                    type="file"
                    name="file"
                    className="question-file"
                    id="fileInput"
                    onChange={(e) =>
                      handlePDFChange(e, setFileName, setFileObject, true)
                    }
                  />
                </label>
                {fileObject.name && (
                  <button
                    type="button"
                    className="remove-question-file-image"
                    onClick={() => {
                      handleRemoveFile(setFileName, setFileObject);
                    }}
                  >
                    Delete PDF
                  </button>
                )}
              </div>

              {/* Update Button */}
              <div className="form-group">
                <button
                  type="submit"
                  className={`btn-primary ${error ? "error" : ""}`}
                  id="update-button"
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default UpdateCourse;
