import React from "react";
import "./styles.scss";

const AnimatedBackground = () => {
  const totalElements = 50;
  const elements = [];

  for (let i = 0; i < totalElements; i++) {
    elements.push(<div className="snow gg-music-note" key={`note-${i}`}></div>);
    elements.push(<div className="snow gg-music" key={`music-${i}`}></div>);
  }

  return <div className="background-animation">{elements}</div>;
};

export default AnimatedBackground;
