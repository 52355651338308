import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchTestData } from "../../components/utils/helper.js";
import "./styles.scss";
function Quiz() {
  const [test, setTest] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState({});
  const [showResults, setShowResults] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    fetchTestData(id, setTest);
  }, [id]);

  const changeQuestion = (index) => {
    setCurrentQuestionIndex(index);
  };

  const handleAnswerClick = (questionIndex, answerIndex) => {
    setUserAnswers((prevUserAnswers) => {
      const updatedUserAnswers = { ...prevUserAnswers };
      if (!updatedUserAnswers[questionIndex]) {
        updatedUserAnswers[questionIndex] = [];
      }

      const answerIndexInArray =
        updatedUserAnswers[questionIndex].indexOf(answerIndex);

      if (answerIndexInArray === -1) {
        updatedUserAnswers[questionIndex].push(answerIndex);
      } else {
        updatedUserAnswers[questionIndex].splice(answerIndexInArray, 1);
      }

      return updatedUserAnswers;
    });
  };
  function arraysAreEqual(array1, array2) {
    if (array1.length !== array2.length) {
      return false;
    }
    const sortedArray1 = array1.slice().sort();
    const sortedArray2 = array2.slice().sort();

    for (let i = 0; i < sortedArray1.length; i++) {
      if (sortedArray1[i] !== sortedArray2[i]) {
        return false;
      }
    }

    return true;
  }

  const calculateScore = () => {
    let score = 0;
    test.questions.forEach((question, questionIndex) => {
      const userSelectedAnswers = userAnswers[questionIndex];
      const numCorrectAnswers = question.answers.reduce((count, answer) => {
        return answer.correct ? count + 1 : count;
      }, 0);
      if (numCorrectAnswers === userSelectedAnswers.length) {
        const correctAnswersIds = question.answers
          .map((element, index) => {
            return element.correct ? index : null;
          })
          .filter((index) => index !== null);
        console.log("correctAnswersIds", correctAnswersIds);
        console.log("userSelectedAnswers", userSelectedAnswers);
        if (arraysAreEqual(correctAnswersIds, userSelectedAnswers)) {
          score++;
        }
      }
    });

    return score;
  };

  const submitAnswers = () => {
    calculateScore();
    setShowResults(true);
  };

  const restartQuiz = () => {
    fetchTestData(id, setTest);
    setUserAnswers({});
    setCurrentQuestionIndex(0);
    setShowResults(false);
  };

  if (test === null) {
    return <div>Loading...</div>;
  }

  const currentQuestion = test.questions
    ? test.questions[currentQuestionIndex]
    : null;
  const fullImagePath = currentQuestion?.filePath
    ? `${process.env.REACT_APP_SHARE_FILE_SERVICE}/${currentQuestion.filePath}`
    : null;

  return (
    <div className="quiz-container" id="quiz">
      {showResults && (
        <div className={`results-container ${showResults ? "slideDown" : ""}`}>
          <h2>Rezultatele testului:</h2>
          {calculateScore() === test.questions.length && (
            <p>Felicitari ai obtinut un punctaj maxim.</p>
          )}
          <p>
            Ai obținut un scor de {calculateScore()} din {test.questions.length}{" "}
            întrebări corecte.
          </p>
          <button className="restart-button" onClick={restartQuiz}>
            Restart
          </button>
        </div>
      )}

      <div className={`question ${showResults ? "hidden" : ""}`}>
        <h1 className="quiz-name" id="quiz-name">
          {test.name}
        </h1>
        <h2 className="question__text">
          {currentQuestion && currentQuestion.text}
        </h2>
        {currentQuestion?.filePath && (
          <img className="question__image" src={fullImagePath} alt="Question" />
        )}
        <div className="quiz-answer-container">
          <div className="answer-first-row">
            {currentQuestion && currentQuestion.answers
              ? currentQuestion.answers.map((answer, index) => {
                  if (index < 2) {
                    return (
                      <div
                        className="answer"
                        key={`answer-${currentQuestionIndex}-row1-${index}`}
                        onClick={() =>
                          handleAnswerClick(currentQuestionIndex, index)
                        }
                      >
                        <input
                          type="checkbox"
                          className="answer__input"
                          id={`choices-${currentQuestionIndex}-${index}`}
                          name={`choices-${currentQuestionIndex}`}
                          value={`choices-${currentQuestionIndex}-${index}`}
                          checked={userAnswers[currentQuestionIndex]?.includes(
                            index
                          )}
                          onChange={() =>
                            handleAnswerClick(currentQuestionIndex, index)
                          }
                        />
                        <label
                          className={`answer__label ${
                            userAnswers[currentQuestionIndex]?.includes(index)
                              ? "selected"
                              : ""
                          }`}
                          htmlFor={`choices-${currentQuestionIndex}-${index}`}
                        >
                          {answer.text}
                        </label>
                      </div>
                    );
                  }
                  return null;
                })
              : null}
          </div>
          <div className="answer-second-row">
            {currentQuestion && currentQuestion.answers
              ? currentQuestion.answers.map((answer, index) => {
                  if (index >= 2) {
                    return (
                      <div
                        className="answer"
                        key={`answer-${currentQuestionIndex}-row2-${index}`}
                        onClick={() =>
                          handleAnswerClick(currentQuestionIndex, index)
                        }
                      >
                        <input
                          type="checkbox"
                          className="answer__input"
                          id={`choices-${currentQuestionIndex}-${index}`}
                          name={`choices-${currentQuestionIndex}`}
                          value={`choices-${currentQuestionIndex}-${index}`}
                          checked={userAnswers[currentQuestionIndex]?.includes(
                            index
                          )}
                          onChange={() =>
                            handleAnswerClick(currentQuestionIndex, index)
                          }
                        />
                        <label
                          className={`answer__label ${
                            userAnswers[currentQuestionIndex]?.includes(index)
                              ? "selected"
                              : ""
                          }`}
                          htmlFor={`choices-${currentQuestionIndex}-${index}`}
                        >
                          {answer.text}
                        </label>
                      </div>
                    );
                  }
                  return null;
                })
              : null}
          </div>
        </div>
        <div className="quiz__buttons-container">
          <button
            className="quiz__button"
            onClick={() => changeQuestion(currentQuestionIndex - 1)}
            disabled={currentQuestionIndex === 0}
          >
            Previous Question
          </button>
          <button
            className="quiz__button"
            onClick={() => changeQuestion(currentQuestionIndex + 1)}
            disabled={currentQuestionIndex === test.questions.length - 1}
          >
            Next Question
          </button>
          <button
            className="quiz__button"
            onClick={submitAnswers}
            disabled={Object.keys(userAnswers).length !== test.questions.length}
          >
            Submit Answers
          </button>
        </div>
      </div>
    </div>
  );
}

export default Quiz;
