import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  fetchTestData,
  getCategoryOptions,
  handleAddAnswer,
  handleAddQuestion,
  handleAnswerCheck,
  handleAnswerTextChange,
  handleCategoryChange,
  handleDeleteAnswer,
  handleDropdownToggle,
  handleImageChange,
  handleQuestionTextChange,
  handleRemoveQuestion,
  handleRemoveQuestionFile,
} from "../../../components/utils/helper.js";
import { AuthContext } from "../../../contexts/AuthProvider";
import DashboardMenu from "../DashboardMenu/DashboardMenu";
import "./styles.scss";

function UpdateTest() {
  const auth = useContext(AuthContext);
  const [category, setCategory] = useState("Select Category");
  const [options, setOptions] = useState([]);
  const [error, setError] = useState(false);
  const [activeCategory, setActiveCategory] = useState("");
  const [questions, setQuestions] = useState([]);
  const [checked, setChecked] = useState([]);
  const [toBeDeleted, setToBeDeleted] = useState({
    questions: [],
    answers: [],
  });
  const [test, setTest] = useState(null);
  const { testId } = useParams();

  useEffect(() => {
    getCategoryOptions(
      setOptions,
      `${process.env.REACT_APP_API_URL}/api/test-categories`
    );
    fetchTestData(testId, setTest);
  }, [testId]);
  useEffect(() => {
    if (test) {
      setActiveCategory(test.category._id);
      setCategory(test.category.rom);
      setQuestions(test.questions);
      const initialChecked = test.questions.map((question) =>
        question.answers.map((answer) => answer.correct)
      );
      setChecked(initialChecked);
    }
  }, [test]);

  const handleNameChange = (event) => {
    setTest({
      ...test,
      name: event.target.value,
    });
  };

  const handleOrderChange = (event) => {
    setTest({
      ...test,
      order: event.target.value,
    });
  };
  const addToDeleteList = (id, type) => {
    setToBeDeleted((prev) => {
      if (type === "question") {
        return {
          ...prev,
          questions: [...prev.questions, id],
        };
      } else if (type === "answer") {
        return {
          ...prev,
          answers: [...prev.answers, id],
        };
      }
    });
  };

  const updateTest = async (event) => {
    event.preventDefault();

    const testName = document.getElementById("testName").value;
    const order = document.getElementById("order").value;

    const formattedQuestions = questions.map((question, questionIndex) => {
      const formattedAnswers = question.answers.map((answer, answerIndex) => {
        return {
          id: answer.id,
          text: answer.text,
          correct:
            (checked[questionIndex] && checked[questionIndex][answerIndex]) ||
            false,
        };
      });
      return {
        id: question.id,
        text: question.text,
        answers: formattedAnswers,
        updatedFile: question.fileUpdate,
        file: question.fileObj,
        fileName: question.fileName,
        fileType: question.fileType,
      };
    });

    const testObject = {
      testId: testId,
      name: testName,
      order: parseInt(order),
      category: activeCategory,
      questions: formattedQuestions,
    };

    try {
      const updateResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/updateFullTest`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "api-key": process.env.REACT_APP_API_KEY,
            token: auth.token,
          },
          body: JSON.stringify(testObject),
        }
      );

      if (updateResponse.status !== 200) {
        setError(true);
        return;
      }
      if (toBeDeleted.questions.length > 0 || toBeDeleted.answers.length > 0) {
        const deleteResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/api/questions-and-answers`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              "api-key": process.env.REACT_APP_API_KEY,
              token: auth.token,
            },
            body: JSON.stringify(toBeDeleted),
          }
        );

        if (deleteResponse.status !== 200) {
          setError(true);
          return;
        }
      }

      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="dashboard">
      <DashboardMenu />
      <div className="test-form-container">
        <div className="title-container">
          <h1 className="title">Update Test</h1>
        </div>
        <div className="form-container">
          <form onSubmit={updateTest}>
            <div className="form-group">
              <label htmlFor="testName">Name:</label>
              <input
                type="text"
                className="custom-title"
                id="testName"
                placeholder="Enter test name"
                value={test ? test.name : ""}
                onChange={handleNameChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="order">Order:</label>
              <input
                type="text"
                className="order"
                id="order"
                placeholder="Number"
                value={test && test.order}
                onChange={handleOrderChange}
              />
            </div>
            <div className="form-group form-group__category">
              <label htmlFor="category">Category:</label>
              <div className="dropdown">
                <button type="button" onClick={handleDropdownToggle}>
                  {category}
                </button>
                <div className="dropdown-menu">
                  {Array.isArray(options) &&
                    options.map((option, index) => (
                      <button
                        key={option._id}
                        className="dropdown-item"
                        onClick={() =>
                          handleCategoryChange(
                            option,
                            setCategory,
                            setActiveCategory
                          )
                        }
                        type="button"
                      >
                        {option.rom}
                      </button>
                    ))}
                </div>
              </div>
            </div>
            {questions.length === 0 && (
              <div className="form-group">
                <button
                  type="button"
                  className="add-question"
                  onClick={() => {
                    handleAddQuestion(setQuestions, questions);
                  }}
                >
                  Add Question
                </button>
              </div>
            )}
            {questions.map((question, questionIndex) => (
              <div
                key={questionIndex}
                className="form-group form-group__add-question"
              >
                <div key={questionIndex} className="question-container">
                  <label htmlFor={`question-${questionIndex + 1}`}>
                    Question:
                  </label>
                  <textarea
                    type="text"
                    className="question-text"
                    id={`question-${questionIndex + 1}`}
                    placeholder="Enter question text"
                    value={question.text}
                    onChange={(event) =>
                      handleQuestionTextChange(
                        event,
                        questionIndex,
                        questions,
                        setQuestions
                      )
                    }
                  />
                  <label htmlFor={`answers-${questionIndex + 1}`}>
                    Answer:
                  </label>
                  <div className="answer-container">
                    {question.answers.map((answer, answerIndex) => (
                      <div key={answerIndex} className="answer-group">
                        <textarea
                          type="text"
                          className="answer-text"
                          id={`answer-${questionIndex + 1}-${answerIndex + 1}`}
                          placeholder="Enter answer text"
                          value={answer.text}
                          onChange={(event) =>
                            handleAnswerTextChange(
                              event,
                              questionIndex,
                              answerIndex,
                              questions,
                              setQuestions
                            )
                          }
                        />
                        <label className="checkbox-label">
                          <input
                            type="checkbox"
                            className="answer-checkbox"
                            id={`check-${questionIndex + 1}-${answerIndex + 1}`}
                            checked={
                              checked[questionIndex] &&
                              checked[questionIndex][answerIndex]
                            }
                            onChange={() =>
                              handleAnswerCheck(
                                questionIndex,
                                answerIndex,
                                checked,
                                setChecked
                              )
                            }
                          />
                        </label>

                        {answerIndex === question.answers.length - 1 && (
                          <div className="answer-buttons">
                            <button
                              type="button"
                              className="add-answer"
                              onClick={() =>
                                handleAddAnswer(
                                  questionIndex,
                                  questions,
                                  setQuestions
                                )
                              }
                            >
                              +
                            </button>
                            {question.answers.length > 1 && (
                              <button
                                type="button"
                                className="delete-answer"
                                onClick={() => {
                                  handleDeleteAnswer(
                                    questionIndex,
                                    answerIndex,
                                    questions,
                                    setQuestions
                                  );
                                  addToDeleteList(answer.id, "answer");
                                }}
                              >
                                -
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="image-buttons">
                  <label className="question-file-upload">
                    <span>Upload Image</span>
                    <input
                      type="file"
                      name="file"
                      className="question-file"
                      id="fileInput"
                      onChange={(e) =>
                        handleImageChange(
                          e,
                          questionIndex,
                          questions,
                          setQuestions,
                          true
                        )
                      }
                    />
                  </label>
                  {(question.file || question.filePath) && (
                    <button
                      type="button"
                      className="remove-question-file-image"
                      onClick={() => {
                        handleRemoveQuestionFile(
                          setQuestions,
                          questions,
                          questionIndex
                        );
                      }}
                    >
                      Delete Image
                    </button>
                  )}
                </div>
                {question.file ? (
                  <img
                    className="uploaded-image"
                    src={question.file}
                    alt="Question"
                  />
                ) : question.filePath ? (
                  <img
                    className="uploaded-image"
                    src={`${process.env.REACT_APP_SHARE_FILE_SERVICE}/${question.filePath}`}
                    alt="Question"
                  />
                ) : null}
                <button
                  type="button"
                  className="remove-question"
                  onClick={() => {
                    handleRemoveQuestion(
                      setQuestions,
                      questions,
                      questionIndex
                    );
                    addToDeleteList(question.id, "question");
                  }}
                >
                  Remove Question
                </button>
                <div className="form-group">
                  {questionIndex === questions.length - 1 && (
                    <>
                      <button
                        type="button"
                        className="add-question"
                        onClick={() => {
                          handleAddQuestion(setQuestions, questions);
                        }}
                      >
                        Add Question
                      </button>
                    </>
                  )}
                </div>
              </div>
            ))}

            <div className="form-group">
              <button
                type="submit"
                className={`btn-primary ${error ? "error" : ""}`}
                id="create-button"
              >
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default UpdateTest;
