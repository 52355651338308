import React from "react";
import { Link } from "react-router-dom";
import DashboardMenu from "../DashboardMenu/DashboardMenu";
import "./styles.scss";

function DashboardCourses() {
  return (
    <div className="dashboard">
      <DashboardMenu />
      <div className="dashboard-home">
        <div className="long-rectangle">
          <span className="dashboard-text">Cursuri</span>
        </div>

        <div className="dashboard-items">
          <Link to="/dashboard/courses/create" className="dashboard-item green">
            <span>Create</span>
          </Link>
          <Link to="/dashboard/courses/edit" className="dashboard-item yellow">
            <span>Edit</span>
          </Link>
          <Link
            to="/dashboard/courses/categories"
            className="dashboard-item blue"
          >
            <span>Categories</span>
          </Link>
        </div>
      </div>
    </div>
  );
}
export default DashboardCourses;
