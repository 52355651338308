import React from "react";
import styled, { keyframes } from "styled-components";

const LoadingWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%) perspective(500px);
`;

const spin = keyframes`
  from {
    opacity: 0.0;
  }
  to {
    opacity: 0.6;
    transform: translate3d(-4px, -4px, 570px);
  }
`;

const Particle = styled.i`
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  opacity: 0;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 10px rgba(255, 255, 255, 1);
  animation: ${spin} 3s infinite ease-in-out;
`;

const LoadingAnimation = () => {
  const particles = [];
  const particleCount = 62;
  const radius = 80;

  for (let i = 1; i <= particleCount; i++) {
    const angle = (i / particleCount) * 720;
    const delay = i * (3 / particleCount);
    particles.push(
      <Particle
        key={i}
        style={{
          transform: `rotate(${angle}deg) translate3d(${radius}px, 0, 0)`,
          animationDelay: `${delay}s`,
        }}
      />
    );
  }

  return <LoadingWrapper>{particles}</LoadingWrapper>;
};

export default LoadingAnimation;
