import React, { useContext, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthProvider";
import "./styles.scss";

function NewPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorOrSuccessMessage, setErrorOrSuccessMessage] = useState("");
  const { userId } = useParams();
  const auth = useContext(AuthContext);

  const resetPassword = async () => {
    if (password !== confirmPassword) {
      setErrorOrSuccessMessage("Passwords do not match");
      return;
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/user/reset_password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "api-key": process.env.REACT_APP_API_KEY,
          },
          body: JSON.stringify({
            userId: userId,
            password: password,
            valid: true,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        auth.login(data.token);
      } else {
        console.error("Login failed");
      }
    } catch (error) {
      console.error(error);
    }
  };
  if (!auth.isAuthenticated()) {
    return (
      <div className="new-password-container">
        <input
          className="input-text"
          type="password"
          id="password"
          value={password}
          placeholder="Parola"
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          className="input-text"
          type="password"
          id="confirm-password"
          value={confirmPassword}
          placeholder="Confirmă parola"
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        {errorOrSuccessMessage && (
          <div className="error-message">{errorOrSuccessMessage}</div>
        )}
        <div className="button-group">
          <button className="reset-button" onClick={() => resetPassword()}>
            Reset
          </button>
        </div>
      </div>
    );
  } else {
    return <Navigate to="/" />;
  }
}

export default NewPassword;
