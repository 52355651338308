import React from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter } from "react-router-dom";
import AppContainer from "./components/main/AppContainer";
import AnimatedBackground from "./components/utils/AnimatedBackground/AnimatedBackground";
import AuthProvider from "./contexts/AuthProvider";

function App() {
  return (
    <div className="app">
      <AnimatedBackground />
      <Helmet>
        <script
          src="https://kit.fontawesome.com/d6a8ce8d77.js"
          crossorigin="anonymous"
        ></script>{" "}
      </Helmet>
      <AuthProvider>
        <BrowserRouter>
          <AppContainer />
        </BrowserRouter>
      </AuthProvider>
    </div>
  );
}

export default App;
