import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthProvider";
import { validationEmail } from "../../../translations/translations";
import "./styles.scss";

function Validate() {
  const { userId } = useParams();
  const [lang, setLang] = useState("rom");
  const [name, setName] = useState("");
  const [error, setError] = useState(false);
  const auth = useContext(AuthContext);

  useEffect(() => {
    const validateUser = async () => {
      const validate = await fetch(
        `${process.env.REACT_APP_API_URL}/api/user/validate/${userId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "api-key": process.env.REACT_APP_API_KEY,
          },
        }
      );

      if (validate.status === 200) {
        const data = await validate.json();
        setLang(data.lang);
        setName(data.name);
        auth.login(data.token);

        console.log("User validated successfully.");
      }
      if (validate.status === 400) {
        setError(true);
      }
    };
    validateUser();
  }, [auth, setError, setLang, setName, userId]);

  if (error) {
    return (
      <div className="validate-container">
        <h1>Erroare</h1>;
      </div>
    );
  } else {
    return (
      <div className="validate-container">
        <h1 className="validate-title">
          {`${validationEmail.congrats[lang]} ${name}!`}
        </h1>
        <p className="validate-text"> {validationEmail.message[lang]}</p>
      </div>
    );
  }
}

export default Validate;
