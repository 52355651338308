import React, { useContext, useEffect, useRef, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthProvider";
import "./styles.scss";

function Tests() {
  const { option, categoryId } = useParams();
  const contentSectionRef = useRef(null);
  const [testsData, setTestsData] = useState([]);
  const [showPopUp, setShowPopUp] = useState(false);
  const [redirectToQuiz, setRedirectToQuiz] = useState(false);
  const [redirectId, setRedirectId] = useState(false);
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const getTestsByCategory = async () => {
      const settings = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
        },
      };

      try {
        const apiCall = await fetch(
          `${process.env.REACT_APP_API_URL}/api/tests-lists/${categoryId}`,
          settings
        );
        const data = await apiCall.json();

        const updatedTestsData = data.data
          .sort((a, b) => a.order - b.order)
          .map((test) => {
            const carouselClass = "show-slider-element";
            return { ...test, carouselClass };
          });

        setTestsData(updatedTestsData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getTestsByCategory();
  }, [categoryId]);

  const scrollToContent = () => {
    contentSectionRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };
  const transitionLeft = () => {
    const elementsOnDisplay = [0, 1, 2, 3];

    const cleanData = testsData.map((item, index) => {
      if (elementsOnDisplay.includes(index)) {
        return { ...item, carouselClass: "hide-slider-element" };
      }
      return item;
    });
    setTestsData(cleanData);

    const lastFourElements = cleanData.slice(-4);
    cleanData.splice(-4);

    for (const element of lastFourElements) {
      cleanData.unshift(element);
    }

    const updatedCarouselItems = cleanData.map((item, index) => {
      if (elementsOnDisplay.includes(index)) {
        return { ...item, carouselClass: "show-slider-element-left" };
      }
      return item;
    });
    setTestsData(updatedCarouselItems);
  };

  const transitionRight = () => {
    const elementsOnDisplay = [0, 1, 2, 3];

    const cleanData = testsData.map((item, index) => {
      if (elementsOnDisplay.includes(index)) {
        return { ...item, carouselClass: "hide-slider-element" };
      }
      return item;
    });
    setTestsData(cleanData);

    const firstFourElements = cleanData.slice(0, 4);

    cleanData.splice(0, 4);

    for (const element of firstFourElements) {
      cleanData.push(element);
    }

    const updatedCarouselItems = cleanData.map((item, index) => {
      if (elementsOnDisplay.includes(index)) {
        return { ...item, carouselClass: "show-slider-element-right" };
      }
      return item;
    });
    setTestsData(updatedCarouselItems);
  };

  const handleQuizClick = (testId) => {
    if (auth.isAuthenticated()) {
      setRedirectId(testId);
      setRedirectToQuiz(true);
    } else {
      setShowPopUp(true);
    }
  };
  const handleLoginClick = () => {
    navigate("/log-in");
  };
  if (redirectToQuiz) {
    return <Navigate to={`/quiz/${redirectId}`} />;
  }
  return (
    <>
      <div className="test-landing-page">
        <div className={`pop-up-container  ${showPopUp ? "" : "hide"}`}>
          <h1>Pentru a accesa testele trebuie să vă autentificați!</h1>
          <div className="buttons-container">
            <button
              className="close-pop-up"
              onClick={() => {
                setShowPopUp(false);
              }}
            >
              Close
            </button>
            <button className="login-redirect" onClick={handleLoginClick}>
              Login
            </button>
          </div>
        </div>
        <div className="test-option-title">{option}</div>
        <div className="arrow" onClick={scrollToContent}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div className="content-section" ref={contentSectionRef}>
        <div className="arrow-left" onClick={transitionLeft}></div>
        <div className="slider-content">
          <div className="first-row">
            {testsData.slice(0, 2).map((test) => (
              <div
                key={test._id}
                className={`square ${test.carouselClass}`}
                onClick={() => {
                  handleQuizClick(test._id);
                }}
              >
                {test.name}
              </div>
            ))}
          </div>
          <div className="second-row">
            {testsData.slice(2, 4).map((test) => (
              <div
                key={test._id}
                className={`square ${test.carouselClass}`}
                onClick={() => {
                  handleQuizClick(test._id);
                }}
              >
                {test.name}
              </div>
            ))}
          </div>
        </div>
        <div className="arrow-right" onClick={transitionRight}></div>
      </div>
    </>
  );
}

export default Tests;
