import React from "react";
import { Link } from "react-router-dom";
import DashboardMenu from "../DashboardMenu/DashboardMenu";
import "./styles.scss";

function Dashboard() {
  return (
    <div className="dashboard">
      <DashboardMenu />
      <div className="dashboard-home">
        <div className="long-rectangle">
          <span className="dashboard-text">Dashboard</span>
        </div>

        <div className="dashboard-items">
          <Link to="/dashboard/tests" className="dashboard-item blue">
            <span>Teste</span>
          </Link>
          <Link to="/dashboard/courses" className="dashboard-item green">
            <span>Cursuri</span>
          </Link>
          <Link to="/admin/statistici" className="dashboard-item red">
            <span>Statistici</span>
          </Link>
          <Link to="/admin/setari" className="dashboard-item yellow">
            <span>Setări</span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
