import {
  faChalkboard,
  faChartBar,
  faCog,
  faFileAlt,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

import "./styles.scss";

function DashboardMenu() {
  return (
    <div className="admin-menu">
      <h3 className="admin-menu-title">Admin Panel</h3>
      <ul className="admin-menu-list">
        <li className="admin-menu-item">
          <Link to="/dashboard/tests" className="admin-menu-link">
            <FontAwesomeIcon icon={faFileAlt} className="admin-menu-icon" />
            <span className="admin-menu-text">Teste</span>
          </Link>
        </li>
        <li className="admin-menu-item">
          <Link to="/dashboard/courses" className="admin-menu-link">
            <FontAwesomeIcon icon={faChalkboard} className="admin-menu-icon" />
            <span className="admin-menu-text">Cursuri</span>
          </Link>
        </li>
        <li className="admin-menu-item">
          <Link to="/admin/utilizatori" className="admin-menu-link">
            <FontAwesomeIcon icon={faUsers} className="admin-menu-icon" />
            <span className="admin-menu-text">Utilizatori</span>
          </Link>
        </li>
        <li className="admin-menu-item">
          <Link to="/admin/statistici" className="admin-menu-link">
            <FontAwesomeIcon icon={faChartBar} className="admin-menu-icon" />
            <span className="admin-menu-text">Statistici</span>
          </Link>
        </li>
        <li className="admin-menu-item">
          <Link to="/admin/setari" className="admin-menu-link">
            <FontAwesomeIcon icon={faCog} className="admin-menu-icon" />
            <span className="admin-menu-text">Setări</span>
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default DashboardMenu;
