import React, { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import LoadingAnimation from "../../components/utils/LoadingAnimation/LoadingAnimation";
import { getDataListByCategory } from "../../components/utils/helper";
import { AuthContext } from "../../contexts/AuthProvider";
import "./styles.scss";

function Courses() {
  const { option, categoryId } = useParams();
  const [coursesData, setCoursesData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [showPopUp, setShowPopUp] = useState(false);
  const [redirectToCourse, setRedirectToCourse] = useState(false);
  const [redirectId, setRedirectId] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  useEffect(() => {
    setLoading(true);
    getDataListByCategory(
      setTotalPages,
      setCoursesData,
      `${process.env.REACT_APP_API_URL}/api/active-courses-lists/${categoryId}/5/${currentPage}`,
      setLoading
    );
  }, [categoryId, currentPage]);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  const prevPage = () => {
    if (currentPage > 1) {
      console.log(currentPage - 1);
      setCurrentPage(currentPage - 1);
    }
  };

  const handleLoginClick = () => {
    navigate("/log-in");
  };
  const handleCourseClick = (courseId) => {
    if (auth.isAuthenticated()) {
      setRedirectId(courseId);
      setRedirectToCourse(true);
    } else {
      setShowPopUp(true);
    }
  };
  const handleClosePopup = (event) => {
    event.stopPropagation();
    setShowPopUp(false);
  };
  if (redirectToCourse) {
    return <Navigate to={`/course/${redirectId}`} />;
  }
  return (
    <div className="courses__landing-page">
      {loading ? (
        <LoadingAnimation />
      ) : (
        <div className="courses__content">
          <div className="courses__option-title">{option}</div>
          <div className={`pop-up-container  ${showPopUp ? "" : "hide"}`}>
            <h1>Pentru a accesa cursurile trebuie să vă autentificați!</h1>
            <div className="buttons-container">
              <button className="close-pop-up" onClick={handleClosePopup}>
                Close
              </button>
              <button className="login-redirect" onClick={handleLoginClick}>
                Login
              </button>
            </div>
          </div>

          {coursesData === undefined ||
            (coursesData.every((course) => !course.availability) && (
              <div className="no-available-courses slideDown">
                <h2>Nu există cursuri disponibile în acest moment.</h2>
              </div>
            ))}
          <ul className="courses__list">
            {coursesData &&
              coursesData.map((course, index) => {
                if (course.availability) {
                  return (
                    <li className="courses__item" key={index}>
                      <span
                        onClick={() => {
                          handleCourseClick(course._id);
                        }}
                        className="courses__item__text"
                      >
                        {course.name}
                      </span>
                    </li>
                  );
                }
                return null;
              })}
          </ul>
          {coursesData &&
            coursesData.length > 0 &&
            coursesData.some((course) => course.availability) && (
              <div className="courses__buttons">
                <button
                  className={`courses__buttons__prev courses__buttons__general ${
                    currentPage === 1
                      ? "courses__buttons__disabled"
                      : "courses__buttons__active"
                  }`}
                  onClick={prevPage}
                  disabled={currentPage === 1}
                >
                  Prev
                </button>
                <button
                  className={`courses__buttons__next courses__buttons__general ${
                    currentPage === totalPages
                      ? "courses__buttons__disabled"
                      : "courses__buttons__active"
                  }`}
                  onClick={nextPage}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            )}
        </div>
      )}
    </div>
  );
}

export default Courses;
