import React, { useContext, useEffect, useState } from "react";
import {
  getCategoryOptions,
  handleCategoryChange,
  handleDropdownToggle,
  handlePDFChange,
  handleRemoveFile,
} from "../../../components/utils/helper.js";
import { AuthContext } from "../../../contexts/AuthProvider";
import DashboardMenu from "../DashboardMenu/DashboardMenu";
import "./styles.scss";

function CourseGenerator() {
  const auth = useContext(AuthContext);
  const [category, setCategory] = useState("Select Category");
  const [options, setOptions] = useState([]);
  const [error, setError] = useState(false);
  const [activeCategory, setActiveCategory] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileObject, setFileObject] = useState({});
  const [availability, setAvailability] = useState(true);

  useEffect(() => {
    getCategoryOptions(
      setOptions,
      `${process.env.REACT_APP_API_URL}/api/course-categories`
    );
  }, []);

  const createCourse = async (event) => {
    event.preventDefault();

    const courseName = document.getElementById("courseName").value;
    const order = document.getElementById("order").value;

    const courseObject = {
      name: courseName,
      order: parseInt(order),
      category: activeCategory,
      availability: availability,
      file: fileObject,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/createCourse`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "api-key": process.env.REACT_APP_API_KEY,
            token: auth.token,
          },
          body: JSON.stringify(courseObject),
        }
      );

      if (response.status !== 200) {
        setError(true);
        return;
      }
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="dashboard">
      <DashboardMenu />
      <div className="test-form-container">
        <div className="title-container">
          <h1 className="title">Add New Course</h1>
        </div>
        <div className="form-container">
          <form onSubmit={createCourse}>
            <div className="form-group">
              <label htmlFor="courseName">Name:</label>
              <input
                type="text"
                className="custom-title"
                id="courseName"
                placeholder="Enter Course name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="order">Order:</label>
              <input
                type="text"
                className="order"
                id="order"
                placeholder="Number"
              />
            </div>
            <div className="form-group form-group__category">
              <label htmlFor="category">Category:</label>
              <div className="dropdown">
                <button type="button" onClick={handleDropdownToggle}>
                  {category}
                </button>
                <div className="dropdown-menu">
                  {Array.isArray(options) &&
                    options.map((option, index) => (
                      <button
                        key={option._id}
                        className="dropdown-item"
                        onClick={() =>
                          handleCategoryChange(
                            option,
                            setCategory,
                            setActiveCategory
                          )
                        }
                        type="button"
                      >
                        {option.rom}
                      </button>
                    ))}
                </div>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="availability">Availability:</label>
              <div className="availability-toggle">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={availability}
                    onChange={() => setAvailability(!availability)}
                  />
                  <span className="slider-box"></span>
                </label>
                <span className="availability-label">
                  {availability ? "On" : "Off"}
                </span>
              </div>
            </div>
            <div className="file-info">
              {fileName && (
                <p>
                  <strong>File Uploaded:</strong> {fileName}
                </p>
              )}
            </div>
            <div className="file-buttons">
              <label className="question-file-upload">
                <span>Upload PDF</span>
                <input
                  type="file"
                  name="file"
                  className="question-file"
                  id="fileInput"
                  onChange={(e) =>
                    handlePDFChange(e, setFileName, setFileObject)
                  }
                />
              </label>
              {fileName && (
                <button
                  type="button"
                  className="remove-question-file-image"
                  onClick={() => {
                    handleRemoveFile(setFileName, setFileObject);
                  }}
                >
                  Delete PDF
                </button>
              )}
            </div>

            <div className="form-group">
              <button
                type="submit"
                className={`btn-primary ${error ? "error" : ""}`}
                id="create-button"
              >
                Create
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CourseGenerator;
