import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getCategoryOptions,
  getDataListByCategory,
  handleCategoryChange,
  handleDropdownToggle,
} from "../../../components/utils/helper";
import { AuthContext } from "../../../contexts/AuthProvider";
import DashboardMenu from "../DashboardMenu/DashboardMenu";
import "./styles.scss";

function EditCourses() {
  const auth = useContext(AuthContext);
  const [category, setCategory] = useState("Select Category");
  const [options, setOptions] = useState([]);
  const [activeCategory, setActiveCategory] = useState("");
  const [coursesData, setCoursesData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    getCategoryOptions(
      setOptions,
      `${process.env.REACT_APP_API_URL}/api/course-categories`
    );
  }, []);

  useEffect(() => {
    getDataListByCategory(
      setTotalPages,
      setCoursesData,
      `${process.env.REACT_APP_API_URL}/api/courses-lists/${activeCategory}/5/${currentPage}`
    );
  }, [activeCategory, currentPage]);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  const prevPage = () => {
    if (currentPage > 1) {
      console.log(currentPage - 1);
      setCurrentPage(currentPage - 1);
    }
  };
  const edit = (courseId) => {
    navigate(`/dashboard/course/edit/${courseId}`);
  };

  const courseDelete = async (courseId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/course/${courseId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "api-key": process.env.REACT_APP_API_KEY,
            token: auth.token,
          },
        }
      );

      if (response.status !== 200) {
        return;
      }

      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className="dashboard">
      <DashboardMenu />
      <div className="test-list__container">
        <div className="test-list__title">
          <h1 className="test-list__title__text">Edit Courses</h1>
        </div>
        <div className="category__container">
          <label className="category__label" htmlFor="category">
            Category:
          </label>
          <div className="dropdown">
            <button type="button" onClick={handleDropdownToggle}>
              {category}
            </button>
            <div className="dropdown-menu">
              {Array.isArray(options) &&
                options.map((option, index) => (
                  <button
                    key={option._id}
                    className="dropdown-item"
                    onClick={() =>
                      handleCategoryChange(
                        option,
                        setCategory,
                        setActiveCategory
                      )
                    }
                    type="button"
                  >
                    {option.rom}
                  </button>
                ))}
            </div>
          </div>
        </div>
        <div className="test-list__data">
          {coursesData &&
            coursesData.map((course) => (
              <>
                <div className="test-list__data-container">
                  <div className="test-list__data-item">
                    {`${course.order}.    ${course.name}`}
                  </div>
                  <div className="test-list__data-buttons">
                    <FontAwesomeIcon
                      className="test-list__data-buttons__edit"
                      icon={faEdit}
                      onClick={() => {
                        edit(course._id);
                      }}
                    />
                    <FontAwesomeIcon
                      className="test-list__data-buttons__delete"
                      icon={faTrash}
                      onClick={() => {
                        courseDelete(course._id);
                      }}
                    />
                  </div>
                </div>
              </>
            ))}
        </div>
        <div className="test-list__buttons-container">
          <button
            className="test-list__buttons-container__button"
            onClick={prevPage}
          >
            Previous
          </button>
          <button
            className="test-list__buttons-container__button"
            onClick={nextPage}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditCourses;
