import jwt_decode from "jwt-decode";
import React, { createContext, useState } from "react";

export const AuthContext = createContext();
// de refacut stocarea sa functioneze cu cookie in loc de local storage
const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("token"));

  const login = (token) => {
    localStorage.setItem("token", token);
    setToken(token);
  };

  const logout = () => {
    localStorage.removeItem("token");
    setToken(null);
  };

  const isAuthenticated = () => {
    return token !== null;
  };

  const isAdmin = () => {
    const decodedToken = jwt_decode(token);
    return decodedToken.role === "admin";
  };

  const isSuperAdmin = () => {
    const decodedToken = jwt_decode(token);
    return decodedToken.role === "administrator";
  };
  return (
    <AuthContext.Provider
      value={{ token, login, logout, isAuthenticated, isAdmin, isSuperAdmin }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
