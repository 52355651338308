import React from "react";
import Header from "../header/Header";
import AppRoutes from "../routes/AppRoutes";
import "./styles.scss";

function AppContainer() {
  return (
    <>
      <Header />
      <AppRoutes />
    </>
  );
}

export default AppContainer;
