import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LoadingAnimation from "../../components/utils/LoadingAnimation/LoadingAnimation";
import PdfViewer from "../../components/utils/PdfViewer/PdfViewer";
import { fetchCourseData } from "../../components/utils/helper";
import "./styles.scss";

function ViewCourse() {
  const { courseId } = useParams();
  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    fetchCourseData(courseId, setCourse, setLoading);
  }, [courseId]);

  const pdfFullPath = course?.file?.filePath
    ? `${process.env.REACT_APP_SHARE_FILE_SERVICE}/${course.file.filePath}`
    : null;

  const isCourseAvailable = course?.availability === true && pdfFullPath;

  return (
    <div className="course__landing-page">
      {loading ? (
        <LoadingAnimation />
      ) : (
        <div className="course__content">
          {isCourseAvailable ? (
            <PdfViewer pdf={pdfFullPath} />
          ) : (
            <div className="no-available-course slideDown">
              <h2>Acest curs momentan nu este disponibil.</h2>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ViewCourse;
