export const handleLogin = async (email, password, auth) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/user/login`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      }
    );

    if (response.ok) {
      const data = await response.json();
      auth.login(data.token);
    } else {
      console.error("Login failed");
    }
  } catch (error) {
    console.error(error);
  }
};
