import React, { useContext, useEffect, useState } from "react";
import {
  getCategoryOptions,
  handleAddAnswer,
  handleAddQuestion,
  handleAnswerCheck,
  handleAnswerTextChange,
  handleCategoryChange,
  handleDeleteAnswer,
  handleDropdownToggle,
  handleImageChange,
  handleQuestionTextChange,
  handleRemoveQuestion,
  handleRemoveQuestionFile,
} from "../../../components/utils/helper.js";
import { AuthContext } from "../../../contexts/AuthProvider";
import DashboardMenu from "../DashboardMenu/DashboardMenu";
import "./styles.scss";

function TestGenerator() {
  const auth = useContext(AuthContext);
  const [category, setCategory] = useState("Select Category");
  const [options, setOptions] = useState([]);
  const [error, setError] = useState(false);
  const [activeCategory, setActiveCategory] = useState("");
  const [questions, setQuestions] = useState([]);
  const [checked, setChecked] = useState([]);
  useEffect(() => {
    getCategoryOptions(
      setOptions,
      `${process.env.REACT_APP_API_URL}/api/test-categories`
    );
  }, []);

  const createTest = async (event) => {
    event.preventDefault();

    const testName = document.getElementById("testName").value;
    const order = document.getElementById("order").value;

    const formattedQuestions = questions.map((question, questionIndex) => {
      const formattedAnswers = question.answers.map((answer, answerIndex) => {
        return {
          text: answer.text,
          correct:
            (checked[questionIndex] && checked[questionIndex][answerIndex]) ||
            false,
        };
      });
      return {
        text: question.text,
        answers: formattedAnswers,
        file: question.fileObj,
        fileName: question.fileName,
        fileType: question.fileType,
      };
    });

    const testObject = {
      name: testName,
      order: parseInt(order),
      category: activeCategory,
      questions: formattedQuestions,
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/createFullTest`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "api-key": process.env.REACT_APP_API_KEY,
            token: auth.token,
          },
          body: JSON.stringify(testObject),
        }
      );

      if (response.status !== 200) {
        setError(true);
        return;
      }

      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="dashboard">
      <DashboardMenu />
      <div className="test-form-container">
        <div className="title-container">
          <h1 className="title">Create New Test</h1>
        </div>
        <div className="form-container">
          <form onSubmit={createTest}>
            <div className="form-group">
              <label htmlFor="testName">Name:</label>
              <input
                type="text"
                className="custom-title"
                id="testName"
                placeholder="Enter test name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="order">Order:</label>
              <input
                type="text"
                className="order"
                id="order"
                placeholder="Number"
              />
            </div>
            <div className="form-group form-group__category">
              <label htmlFor="category">Category:</label>
              <div className="dropdown">
                <button type="button" onClick={handleDropdownToggle}>
                  {category}
                </button>
                <div className="dropdown-menu">
                  {Array.isArray(options) &&
                    options.map((option, index) => (
                      <button
                        key={option._id}
                        className="dropdown-item"
                        onClick={() =>
                          handleCategoryChange(
                            option,
                            setCategory,
                            setActiveCategory
                          )
                        }
                        type="button"
                      >
                        {option.rom}
                      </button>
                    ))}
                </div>
              </div>
            </div>
            {questions.length === 0 && (
              <div className="form-group">
                <button
                  type="button"
                  className="add-question"
                  onClick={() => {
                    handleAddQuestion(setQuestions, questions);
                  }}
                >
                  Add Question
                </button>
              </div>
            )}
            {questions.map((question, questionIndex) => (
              <div
                key={questionIndex}
                className="form-group form-group__add-question"
              >
                <div key={questionIndex} className="question-container">
                  <label htmlFor={`question-${questionIndex + 1}`}>
                    Question:
                  </label>
                  <textarea
                    type="text"
                    className="question-text"
                    id={`question-${questionIndex + 1}`}
                    placeholder="Enter question text"
                    value={question.text}
                    onChange={(event) =>
                      handleQuestionTextChange(
                        event,
                        questionIndex,
                        questions,
                        setQuestions
                      )
                    }
                  />
                  <label htmlFor={`answers-${questionIndex + 1}`}>
                    Answer:
                  </label>
                  <div className="answer-container">
                    {question.answers.map((answer, answerIndex) => (
                      <div key={answerIndex} className="answer-group">
                        <textarea
                          type="text"
                          className="answer-text"
                          id={`answer-${questionIndex + 1}-${answerIndex + 1}`}
                          placeholder="Enter answer text"
                          onChange={(event) =>
                            handleAnswerTextChange(
                              event,
                              questionIndex,
                              answerIndex,
                              questions,
                              setQuestions
                            )
                          }
                        />
                        <label className="checkbox-label">
                          <input
                            type="checkbox"
                            className="answer-checkbox"
                            id={`check-${questionIndex + 1}-${answerIndex + 1}`}
                            defaultChecked={answer.checked}
                            onClick={() =>
                              handleAnswerCheck(
                                questionIndex,
                                answerIndex,
                                checked,
                                setChecked
                              )
                            }
                          />
                        </label>

                        {answerIndex === question.answers.length - 1 && (
                          <div className="answer-buttons">
                            <button
                              type="button"
                              className="add-answer"
                              onClick={() =>
                                handleAddAnswer(
                                  questionIndex,
                                  questions,
                                  setQuestions
                                )
                              }
                            >
                              +
                            </button>
                            {question.answers.length > 1 && (
                              <button
                                type="button"
                                className="delete-answer"
                                onClick={() =>
                                  handleDeleteAnswer(
                                    questionIndex,
                                    answerIndex,
                                    questions,
                                    setQuestions
                                  )
                                }
                              >
                                -
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="image-buttons">
                  <label className="question-file-upload">
                    <span>Upload Image</span>
                    <input
                      type="file"
                      name="file"
                      className="question-file"
                      id="fileInput"
                      onChange={(e) =>
                        handleImageChange(
                          e,
                          questionIndex,
                          questions,
                          setQuestions
                        )
                      }
                    />
                  </label>
                  <button
                    type="button"
                    className="remove-question-file-image"
                    onClick={() => {
                      handleRemoveQuestionFile(
                        setQuestions,
                        questions,
                        questionIndex
                      );
                    }}
                  >
                    Delete Image
                  </button>
                </div>
                {question.file && question.file.length > 0 && (
                  <img
                    className="uploaded-image"
                    src={question.file}
                    alt="Question"
                  />
                )}

                <div className="form-group">
                  {questionIndex === questions.length - 1 && (
                    <>
                      <button
                        type="button"
                        className="add-question"
                        onClick={() => {
                          handleAddQuestion(setQuestions, questions);
                        }}
                      >
                        Add Question
                      </button>
                      <button
                        type="button"
                        className="remove-question"
                        onClick={() => {
                          handleRemoveQuestion(
                            setQuestions,
                            questions,
                            questionIndex
                          );
                        }}
                      >
                        Remove Question
                      </button>
                    </>
                  )}
                </div>
              </div>
            ))}

            <div className="form-group">
              <button
                type="submit"
                className={`btn-primary ${error ? "error" : ""}`}
                id="create-button"
              >
                Create
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default TestGenerator;
